.errors {
    color: red;
}

.comment-form-box {
    height: fit-content;
    margin: auto;
    width: 80%;
    border: 1px solid gray;
    background-color: gainsboro;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.comment-form-box i {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    margin-right: 5px;
}

.comment-form-box input {
    height: 30px;
    width: 85%;
    margin: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: aliceblue;
}

.comment-form-box button {
    margin: 8px 4px;
    color: white;
    background-color: rgb(255, 75, 20);
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    padding: 8px;
    box-shadow: 2px 2px black;
    margin-bottom: 10px;
}

#comment-image {
    height: 40px;
    width: 40px;
    margin-left: 10px;
}
