.splash-image {
    background-image: url("https://soundcloudaudiofiles.s3.us-west-1.amazonaws.com/loud3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height:555px;
    width: 1240px;
    margin: auto;
   /* filter: contrast(150%); */
   filter: brightness(90%);
}

.index-landing {
    width: 1240px;
    min-height: 1000px;
    margin: auto;
    background-color: white;
}

.hear {
    font-size: 1.5em;
    text-align: center;
}


.splash-text {
    font-size: 2.5em;
    text-align: center;
    font-family: 'Bungee', cursive;
    color: rgb(219, 50, 75);
    padding-top: 200px;
}

.splash-p {
    font-size: 1.3em;
    font-family: 'Bungee', cursive;
    color: rgb(219, 50, 75);
    text-align: center;
}
