.modal-button {
    border: 2px solid black;
    background-color: rgb(95, 95, 95);
    color: white;
    box-shadow: 2px 2px black;
}
.comment-detail-box{
    /* padding-top: 2px; */
    padding-bottom: 35px;
}

.one-comment-box {
    margin: 35px 0 0 61.44px;

}

.one-comment-box h4 {
    margin-bottom: 1px;
    font-weight: 900;
}

.one-comment-box h5 {
    margin-top: 4px;
    margin-bottom: 1px;
    color: rgb(92, 92, 92);
}
.comment-buttons{
    display:flex;

}
#deletebtn{
    margin-right: 10px;
}

#noComment {
    display: flex;
    flex-direction: column;
    align-items: center;
}
