.signup-outer-box {
    display: block;
    text-align: center;
}

.signup-box {
    display: flex;
    border-radius: 25px;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: 400px;

}
/* #modal-content{
    set border for every modal on site
    border: 3px solid black;
    border-radius: 5px;
} */

.signup-input{
    display: inline-block;
    border: 1px solid rgb(135, 135, 135);
    padding: 12px;
    border-radius: 5px;
    font-size: 14px;
    width: 350px;
    margin-bottom: 0.5em;
}

#signup-button{
    font-size: 14px;
    padding: 12px;
    width: 376px;
    margin-top: 15px;
    margin-bottom: 15px;
}
