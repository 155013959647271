@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab&display=swap);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
}


.profile-dropdown {
  position: absolute;
  z-index: 1000;
  border: 1px solid gainsboro;
}

.profile-dropdown li {
  padding-bottom: 5px;
  padding-top: 5px;
}

.profile-dropdown a {
  text-decoration: none;
  color: black;
  display: block;
}

.clicky {
  background-color: gainsboro;
}

.clicky:hover {
  background-color: gray;
}

#profile-link {
  border-bottom: 1px solid white;
}

.fa-cloud-bolt {
  color: rgb(219, 50, 75);
}


#logo {
  font-size: 1.5rem;
  text-decoration: none;
  color: rgb(219, 50, 75);
  font-family: 'Bungee', cursive;
  margin-left: .5em;
}

.logo-li {
  margin-right: auto;
  padding-left: 5px;
}

#noDeco{
  text-decoration: none;
}

.nav-bar {
  display: flex;
  /* justify-content: flex-end; */
  justify-content: space-around;
  align-items: center;
  /* padding-top: 8px;
  padding-bottom: 8px;
  width: 1240px; */
  padding: 5px 0px;
  width: 95%;
  list-style-type: none;
  /* border-bottom: 1px solid rgb(172, 168, 168);
  background-color: rgb(69, 69, 69);
  margin: auto; */
}

.nav-background {
  background-color: rgb(69, 69, 69);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.nav-bar > li {
  padding-right: 1em;
}
/*
.signup-button{
  margin-left: auto;
} */

.li-nav-bar {
  display: flex;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
}

.create-spot-link {
  text-decoration: none;
  color: rgb(84, 84, 212);
}

.menu-div {
  position: relative;
}

.menu-div ul {
  position: absolute;
  list-style-type: none;
  top: 35px;
  right: 5px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  width: 150px;
  align-items: center;
  background-color: white;
  text-align: center;
}

.manage-li {
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  padding: 3px 0;
  margin: 4px 0;
}

.manage-link {
  text-decoration: none;
  color: black;
}

.logout-button {
  background-color: rgb(79, 78, 78);
  border: 2px solid black;
  color: white;
  border-radius: 45%;
  padding: 10px 17px;
  cursor: pointer;
}

.searchbar-li {
  position: absolute;
  left: 40%;
}

.search-input {
  padding: 6px;
  border-radius: 5px;
  border: 0;
}

/* .search-button {
  background-image: ;
} */

.menu-button {
  cursor: pointer;
}

.profile-menu {
  height: 33px;
  width: 33px;
  object-fit: cover;

  border-radius: 45%;
}

.fa-angle-down {
  font-size: small;
  color: gray;
  margin-left: 3px;
  vertical-align: super;
  cursor: pointer;
}

.menu-button:hover .fa-angle-down {
  color: white;
}

.navlink-link {
  text-decoration: none;
  color: gray;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.navlink-link:hover {
  color: white;
  text-decoration: underline;
}

.likedSongsClass {
  color: grey;
  text-decoration: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.likedSongsClass:hover {
  color: white;
  text-decoration: underline;
}

.song-upload-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-between;
    width:1000px;
    justify-content: center;
}

.upload-div label{
    margin: 30px 30px;

}

#upload-song-btn{
    margin: 20px;
}

#free-link{
    margin: 20px;

}

.song-inputs{
    margin-left: 5px;
}

.upload-validators{
    padding: 5px;
    color: crimson;
    margin: 0;
}
#upload-form-data{
    width: 800px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid lightgrey;
    padding: 25px 25px;
}
.upload-form-elements{
    width:400px;
    padding-left: 170px;
}


.login-outer-box {
    display: block;
    text-align: center;
  }
  
  .login-box {
    display: flex;
    border-radius: 25px;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: 400px;
  }
  
  
  .input {
    display: inline-block;
    border: 1px solid rgb(135, 135, 135);
    padding: 12px;
    border-radius: 5px;
    font-size: 14px;
    width: 350px;
    margin-bottom: 0.5em;
  }
  
  #login-button {
    font-size: 14px;
    padding: 12px;
    width: 376px;
    margin-top: 15px;
  }

  #login-demo {
    font-size: 14px;
    padding: 12px;
    width: 376px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  #demo {
    text-decoration: underline;
    color: darkslategray;
    cursor: pointer;
  }
  
  .highlight {
    background-color: blue;
  }
  
  #demo {
    margin: 0;
  }
.signup-outer-box {
    display: block;
    text-align: center;
}

.signup-box {
    display: flex;
    border-radius: 25px;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: 400px;

}
/* #modal-content{
    set border for every modal on site
    border: 3px solid black;
    border-radius: 5px;
} */

.signup-input{
    display: inline-block;
    border: 1px solid rgb(135, 135, 135);
    padding: 12px;
    border-radius: 5px;
    font-size: 14px;
    width: 350px;
    margin-bottom: 0.5em;
}

#signup-button{
    font-size: 14px;
    padding: 12px;
    width: 376px;
    margin-top: 15px;
    margin-bottom: 15px;
}

#footer-box {
    background-color: rgb(219, 50, 75);
    /* border-top: 1px solid black; */
    /* position: fixed; */
    position: -webkit-sticky;
    position: sticky;
    border-top: 1px solid rgb(219, 50, 75);
    width: 100%;
    /* margin: 0 auto;
    bottom: 0;
    z-index: 999; */
    display: flex;
    justify-content: space-around;
    padding: 6px 2px 4px 2px;
    height: 3rem;
    bottom: 1;
}

.footer-person {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.personal-details {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.person-pic {
    transition: all 0.6s;
    border-radius: 50%;
    border: solid 0.1rem rgb(69, 69, 69);
    width: 2.4rem;
}

.person-pic:hover {
    transition: all 0.6s;
    width: 2.8rem;
}

.footer-link-icons {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.fa-brands {
    font-size: 30px;
    color: rgb(69, 69, 69);
    transition: all 0.6s;
}

.fa-brands:hover {
    transition: all 0.6s;
    font-size: 33px;
}

.player-background {
    background-color: rgb(69, 69, 69);
    position: -webkit-sticky;
    position: sticky;
    /* bottom: 3rem; */
    bottom: 0;
    z-index: 999;
    width: 100%;
}

.rhap_container {
    background-color: rgb(69, 69, 69);
}

/* .rhap_progress-section {
    background-color: black;
} */

.rhap_time {
    color: black;
}

/* .rhap_progress-container {
    background-color: black;
} */

.rhap_progress-bar {
    background-color: rgb(219, 50, 75);
}

.rhap_progress-indicator {
    background-color: black;
}

.rhap_repeat-button {
    color: black;
}

.rhap_main-controls-button {
    color: black;
}

.rhap_volume-button {
    color: black;
}

.rhap_volume-bar {
    background-color: rgb(219, 50, 75);
}

.rhap_volume-indicator {
    background-color: black;
}

.song-card {
    margin-bottom: 1em;
    height: 225px;
    width: 180px;
}

.song-card-link {
    text-decoration: none;
    color: black;
}

.song-details {
    margin-top: .3em;
    display: flex;
    justify-content: space-between;
}

.song-details > p {
    margin: 0;
}

.song-card img {
    object-fit: cover;
    height: 180px;
    width: 180px;
}

.artist-name {
    font-size: .8em;
    color:rgb(153, 153, 153);
    padding-top: 3px;
    margin: 0;
}
.card-image {
    position: relative;
}

.song-play {
    position: absolute;
    top: 35%;
    left: 35%;
    cursor: pointer;
}

.fa-circle-play{
    color: rgb(240, 63, 24);
}

.song-play:hover .fa-circle-play{
    color: rgb(228, 38, 13);
}

.song-track-name:hover{
    text-decoration: underline;
}

.song-track-name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    white-space: nowrap;
    /* padding: none; */
    margin: 0;
}

.song-likes-num{
    display: flex;
    justify-content: right;
    align-items: top;
    /* position: relative;
    bottom: 38px; */
    margin: 0;
}

#songcard-heart{
    border: none;
    padding-top: 1px;
}

.all-songs {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
    width: 1240px;
    margin: auto;
    justify-content: center;
}
.errors {
    color: red;
}

.comment-form-box {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    width: 80%;
    border: 1px solid gray;
    background-color: gainsboro;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.comment-form-box i {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    margin-right: 5px;
}

.comment-form-box input {
    height: 30px;
    width: 85%;
    margin: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: aliceblue;
}

.comment-form-box button {
    margin: 8px 4px;
    color: white;
    background-color: rgb(255, 75, 20);
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    padding: 8px;
    box-shadow: 2px 2px black;
    margin-bottom: 10px;
}

#comment-image {
    height: 40px;
    width: 40px;
    margin-left: 10px;
}

.delete-modal {
    height: 220px;
    width: 400px;
    /* border: 3px solid black; */
    /* border-radius: 5px; */
    display: flex;
    flex-direction: column;
}

.delete-modal h2 {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.delete-modal p {
    margin: auto;
}

.delete-modal button {
    padding: 8px;
    cursor: pointer;
}

.yes-button {
    background-color: rgb(219, 50, 75);
    color: white;
    margin: 2px 10px;
}

.no-button {
    background-color: gray;
    color: white;
    margin: 2px 10px 15px;
}
.errors {
    color: red;
    text-align: center;
}

.edit-modal {
    height: 220px;
    width: 400px;
    border: 3px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.edit-modal h2 {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.edit-modal textarea {
    height: 200px;
    margin: 13px;
    border: 2px solid black;
    border-radius: 3px;
    background-color: aliceblue;
}

.edit-modal button {
    margin: 2px 10px;
    padding: 8px;
    cursor: pointer;
}

.edit-button {
    color: white;
    background-color: rgb(255, 75, 20);
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    padding: 8px;

}

.cancel-button {
    background-color: gray;
    color: white;
}

.modal-button {
    border: 2px solid black;
    background-color: rgb(95, 95, 95);
    color: white;
    box-shadow: 2px 2px black;
}
.comment-detail-box{
    /* padding-top: 2px; */
    padding-bottom: 35px;
}

.one-comment-box {
    margin: 35px 0 0 61.44px;

}

.one-comment-box h4 {
    margin-bottom: 1px;
    font-weight: 900;
}

.one-comment-box h5 {
    margin-top: 4px;
    margin-bottom: 1px;
    color: rgb(92, 92, 92);
}
.comment-buttons{
    display:flex;

}
#deletebtn{
    margin-right: 10px;
}

#noComment {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.song-img-box {
    display: flex;
    grid-gap: 3px;
    gap: 3px;
    width: 100%;
    justify-content: center;
    position: relative;
}

.song-img-box>img {
    width: 500px;
    max-height: 500px;
    object-fit: cover;
}

.song-play-button {
    position: absolute;
    top: 45%;
    left: 48%;
}

.song-detail-box {
    margin: 40px;
    border-bottom: 2px solid black;

}

.under-pics {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.callout-info-box {
    border: 3px solid black;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.callout-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.callout-price {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.callout-info-box button {
    margin: 2px 10px;
    padding: 8px;
    cursor: pointer;
    background-color: rgb(242, 90, 90);
    color: white;
    box-shadow: 3px 3px black;
}

.heart-likes-div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.no-songs-index {
    display: flex;
    flex-direction: column;
}

.no-songs-subtext {
    display: flex;
    flex-direction: column;
}

.all-songs {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
    width: 1240px;
    margin: auto;
    justify-content: center;
}

#audio-btn{
    display:none
}
#image-btn{
    display:none
}

.song-edit-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 500px
}

.button-center {
    margin-left: 40%;
    margin-top: .5em;
}

#edit-song-data{
    display:flex;
    flex-direction: column;
    width: 400px;

}

.song-edit-inputs{
    margin: 10px 0 10px;
    /* display: flex; */

}

.song-edit-errors{
    color: crimson;
    margin: 0 0 0 100px;
}

.edit-bars{
    margin-left: 5px;
}

#edit-song-btn{
    display: flex;
    justify-content: center;
    margin: 10px 40px;
}

.all-user-songs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
    width: 1240px;
    margin: auto;
    justify-content: center;
}

.user-song-button-container {
    flex-direction: column;
    /* height: min-content; */
    /* margin: 2rem; */
}

.user-song-buttons {
    display: flex;
    justify-content: space-evenly;
}

.user-song-buttons button {
    width: 80px
}
.prof-img-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem 1rem  2rem;
}

.cancel-click {
    cursor: pointer;
    display: inline-block;
    margin-left: 1em;
}
.album-like {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.tracks-likes-div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
}

.profile-header{
    height: 300px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-left: 10em;
    position: relative;
}

.profile-header h1 {
    margin-left: 5%;
    background-color: black;
    color: rgb(219, 50, 75);
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 50;
}

.plus-button {
    position: absolute;
    top: 55%;
    left: 8%;
}

button {
    cursor: pointer;
}

#profile-image {
    height: 200px;
    width: 200px;
    object-fit: fill;
    border-radius: 200%;
}

.track-image {
    height: 160px;
    width: 160px;
    object-fit: cover;
}

.likes-list-div {
    display: flex;
    flex-flow: row;
    padding: 5px;
}

.likes-list-div p {
    margin: 3px;
    padding-left: 5px;
}

.profile-h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5em;
    color: gray;
}

.link1 {
    text-decoration: none;
    color: black;
}

.profile-h1:hover {
    color: darkgray;
}

#prof-heart {
    border: 0;
    font-size: 1em;
}
.splash-image {
    background-image: url("https://soundcloudaudiofiles.s3.us-west-1.amazonaws.com/loud3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height:555px;
    width: 1240px;
    margin: auto;
   /* filter: contrast(150%); */
   -webkit-filter: brightness(90%);
           filter: brightness(90%);
}

.index-landing {
    width: 1240px;
    min-height: 1000px;
    margin: auto;
    background-color: white;
}

.hear {
    font-size: 1.5em;
    text-align: center;
}


.splash-text {
    font-size: 2.5em;
    text-align: center;
    font-family: 'Bungee', cursive;
    color: rgb(219, 50, 75);
    padding-top: 200px;
}

.splash-p {
    font-size: 1.3em;
    font-family: 'Bungee', cursive;
    color: rgb(219, 50, 75);
    text-align: center;
}

/* TODO Add site wide styles */

#root {
  min-height: 100vh;
  /* padding-bottom: 3rem; */
  
}

ul {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background-color: gainsboro;
}

.index {
  width: 1240px;
  min-height: 1000px;
  margin: auto;
  background-color: white;
  padding-top: 3em;
}

.hidden {
  display: none;
}

.zero-opacity {
  opacity: 0;
}

.button-orange {
    color: white;
    background-color: rgb(255, 75, 20);
    cursor: pointer;
    border: 1px solid rgb(255, 75, 20);
    border-radius: 3px;
    padding: 8px;
  }

  .button-black {
    color: white;
    background-color: rgb(69, 69, 69);
    cursor: pointer;
    border: 1px solid white;
    border-radius: 3px;
    padding: 8px;
  }

  .errors {
    color: red;
    margin: 0;
    list-style: none;
  }

  .button-white {
    background-color: white;
    border: 1px solid gray;
    border-radius: 3px;
    cursor: pointer;
    padding: 2px;
  }

  .button-white:hover {
    border: 1px solid darkgray;
  }

  .fa-heart {
    color: rgb(255, 75, 20);
    background-color: white;
    border: 1px solid gainsboro;
    padding: 2px 4px;
    font-size: large;
    margin-right: 5px;
  }

  .fa-heart-broken {
    background-color: white;
    border: 1px solid gainsboro;
    padding: 2px 4px;
    font-size: large;
    margin-right: 5px;
  }

