/* TODO Add site wide styles */

#root {
  min-height: 100vh;
  /* padding-bottom: 3rem; */
  
}

ul {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background-color: gainsboro;
}

.index {
  width: 1240px;
  min-height: 1000px;
  margin: auto;
  background-color: white;
  padding-top: 3em;
}

.hidden {
  display: none;
}

.zero-opacity {
  opacity: 0;
}

.button-orange {
    color: white;
    background-color: rgb(255, 75, 20);
    cursor: pointer;
    border: 1px solid rgb(255, 75, 20);
    border-radius: 3px;
    padding: 8px;
  }

  .button-black {
    color: white;
    background-color: rgb(69, 69, 69);
    cursor: pointer;
    border: 1px solid white;
    border-radius: 3px;
    padding: 8px;
  }

  .errors {
    color: red;
    margin: 0;
    list-style: none;
  }

  .button-white {
    background-color: white;
    border: 1px solid gray;
    border-radius: 3px;
    cursor: pointer;
    padding: 2px;
  }

  .button-white:hover {
    border: 1px solid darkgray;
  }

  .fa-heart {
    color: rgb(255, 75, 20);
    background-color: white;
    border: 1px solid gainsboro;
    padding: 2px 4px;
    font-size: large;
    margin-right: 5px;
  }

  .fa-heart-broken {
    background-color: white;
    border: 1px solid gainsboro;
    padding: 2px 4px;
    font-size: large;
    margin-right: 5px;
  }
