.song-card {
    margin-bottom: 1em;
    height: 225px;
    width: 180px;
}

.song-card-link {
    text-decoration: none;
    color: black;
}

.song-details {
    margin-top: .3em;
    display: flex;
    justify-content: space-between;
}

.song-details > p {
    margin: 0;
}

.song-card img {
    object-fit: cover;
    height: 180px;
    width: 180px;
}

.artist-name {
    font-size: .8em;
    color:rgb(153, 153, 153);
    padding-top: 3px;
    margin: 0;
}
.card-image {
    position: relative;
}

.song-play {
    position: absolute;
    top: 35%;
    left: 35%;
    cursor: pointer;
}

.fa-circle-play{
    color: rgb(240, 63, 24);
}

.song-play:hover .fa-circle-play{
    color: rgb(228, 38, 13);
}

.song-track-name:hover{
    text-decoration: underline;
}

.song-track-name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    white-space: nowrap;
    /* padding: none; */
    margin: 0;
}

.song-likes-num{
    display: flex;
    justify-content: right;
    align-items: top;
    /* position: relative;
    bottom: 38px; */
    margin: 0;
}

#songcard-heart{
    border: none;
    padding-top: 1px;
}
