.errors {
    color: red;
    text-align: center;
}

.edit-modal {
    height: 220px;
    width: 400px;
    border: 3px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.edit-modal h2 {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.edit-modal textarea {
    height: 200px;
    margin: 13px;
    border: 2px solid black;
    border-radius: 3px;
    background-color: aliceblue;
}

.edit-modal button {
    margin: 2px 10px;
    padding: 8px;
    cursor: pointer;
}

.edit-button {
    color: white;
    background-color: rgb(255, 75, 20);
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    padding: 8px;

}

.cancel-button {
    background-color: gray;
    color: white;
}
