.song-img-box {
    display: flex;
    gap: 3px;
    width: 100%;
    justify-content: center;
    position: relative;
}

.song-img-box>img {
    width: 500px;
    max-height: 500px;
    object-fit: cover;
}

.song-play-button {
    position: absolute;
    top: 45%;
    left: 48%;
}

.song-detail-box {
    margin: 40px;
    border-bottom: 2px solid black;

}

.under-pics {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.callout-info-box {
    border: 3px solid black;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.callout-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.callout-price {
    display: flex;
    align-items: center;
    gap: 4px;
}

.callout-info-box button {
    margin: 2px 10px;
    padding: 8px;
    cursor: pointer;
    background-color: rgb(242, 90, 90);
    color: white;
    box-shadow: 3px 3px black;
}

.heart-likes-div {
    display: flex;
    flex-direction: row;
    align-items: center;
}