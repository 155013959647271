.delete-modal {
    height: 220px;
    width: 400px;
    /* border: 3px solid black; */
    /* border-radius: 5px; */
    display: flex;
    flex-direction: column;
}

.delete-modal h2 {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.delete-modal p {
    margin: auto;
}

.delete-modal button {
    padding: 8px;
    cursor: pointer;
}

.yes-button {
    background-color: rgb(219, 50, 75);
    color: white;
    margin: 2px 10px;
}

.no-button {
    background-color: gray;
    color: white;
    margin: 2px 10px 15px;
}