@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab&display=swap');

.album-like {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.tracks-likes-div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
}

.profile-header{
    height: 300px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-left: 10em;
    position: relative;
}

.profile-header h1 {
    margin-left: 5%;
    background-color: black;
    color: rgb(219, 50, 75);
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 50;
}

.plus-button {
    position: absolute;
    top: 55%;
    left: 8%;
}

button {
    cursor: pointer;
}

#profile-image {
    height: 200px;
    width: 200px;
    object-fit: fill;
    border-radius: 200%;
}

.track-image {
    height: 160px;
    width: 160px;
    object-fit: cover;
}

.likes-list-div {
    display: flex;
    flex-flow: row;
    padding: 5px;
}

.likes-list-div p {
    margin: 3px;
    padding-left: 5px;
}

.profile-h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5em;
    color: gray;
}

.link1 {
    text-decoration: none;
    color: black;
}

.profile-h1:hover {
    color: darkgray;
}

#prof-heart {
    border: 0;
    font-size: 1em;
}